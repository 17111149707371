import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { _jobs } from 'src/app/router/route-data'
import { JobService, RegionQuery } from 'src/app/shared/services/job.service'
import { Taxonomy } from 'src/app/shared/data/types'
import { Subscription } from "rxjs";
import { LayoutService } from 'src/app/shared/services/layout.service'
import { WordpressService } from "../../../shared/services/wordpress.service";

const FALLBACK_BAR_HEIGHT = 70

interface SearchForm {
  searchString: FormControl<string>;
  location: FormControl<string>;
  perimeter: FormControl<number>;
}

@Component({
  selector: 'app-job-search-small',
  templateUrl: './job-search-small.component.html',
  styleUrls: ['./job-search-small.component.scss']
})
export class JobSearchSmallComponent implements OnInit, OnDestroy {
  @ViewChild('headContainer', { static: true }) headContainer
  @ViewChild('headContainerMobile', { static: true }) headContainerMobile

  // Data
  regions: Taxonomy[] = []
  regionQuery: RegionQuery
  regionQuerySub: Subscription
  locationQuerySub: Subscription
  regionsSub: Subscription
  autocompleteLocations: string[] = [];

  _jobs = `${_jobs}`

  searchForm: FormGroup<SearchForm> = this.formBuilder.group({ searchString: [''], location: [''], perimeter: new FormControl(25) })

  get location(): AbstractControl<string, string> {
    return this.searchForm.get('location')
  }
  get searchString(): AbstractControl<string, string> {
    return this.searchForm.get('searchString')
  }
  get perimeter(): AbstractControl<number, number> {
    return this.searchForm.get('perimeter')
  }

  constructor(
    private jobService: JobService,
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    private wordpressService: WordpressService,
  ) {
  }

  ngOnInit(): void {
    this.emitHeight()

    this.location.valueChanges.subscribe((query) => {
      this.wordpressService.getAutocompleteResults(query).subscribe(results => {
        this.autocompleteLocations = results;
      });
    })
    this.locationQuerySub = this.jobService.$locationQuery.subscribe(query => {
      this.searchForm.patchValue(query, { emitEvent: false });
    })
  }

  emitHeight(): void {
    let height = FALLBACK_BAR_HEIGHT

    if (this.headContainer && this.headContainerMobile) {
      const desktopHeight = this.headContainer.nativeElement.offsetHeight
      const mobileHeight = this.headContainerMobile.nativeElement.offsetHeight

      height = desktopHeight > mobileHeight ? desktopHeight : mobileHeight
      if (!height || height === 0) {
        height = FALLBACK_BAR_HEIGHT
      }
    }

    this.layoutService.heroHeightSubject.next(height + 'px')
  }

  ngOnDestroy(): void {
    if (this.locationQuerySub) this.locationQuerySub.unsubscribe()
    if (this.regionQuerySub) this.regionQuerySub.unsubscribe()
    if (this.regionsSub) this.regionsSub.unsubscribe()
    this.layoutService.heroHeightSubject.next('0px')
  }

  search(): void {
    this.jobService.newLocationQuery(this.searchString.value, this.location.value, this.perimeter.value);
  }
}
