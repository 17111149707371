import { Injectable, OnDestroy } from "@angular/core";
import {
  BehaviorSubject,
  debounceTime, distinctUntilChanged,
  EMPTY,
  from, merge,
  Observable,
  of,
  ReplaySubject, shareReplay,
  Subject,
  Subscription,
} from "rxjs";
import { Job, JobListElement, Taxonomy } from '../data/types'
import { WordpressService } from './wordpress.service'
import { catchError, filter, map, switchMap, take } from "rxjs/operators";
import { MatomoTracker } from "@ngx-matomo/tracker";

export interface LocationQuery {
  searchString: string;
  location: string;
  perimeter: number;
}

export interface RegionQuery {
  startCursor: number
  pageSize: number
  searchString: string
  region: Taxonomy
}

export interface SearchQuery {
  searchString: string
  locationString: string
  distanceInKm: string
}

const DEFAULT_REGION = '__DEFAULT__'

@Injectable({ providedIn: 'root' })
export class JobService implements OnDestroy {
  private regionsSubject = new ReplaySubject<Taxonomy[]>(1)

  public isFirstSearch: boolean = true
  private lastActiveJobId: string = ''
  private lastActiveJob: Job
  private activeJobSubject = new Subject<Job>()
  private readonly stateSub: Subscription

  $activeJob = this.activeJobSubject.asObservable()
  $regions: Observable<undefined | Taxonomy[]> = this.regionsSubject.asObservable()

  // Shared
  /////////

  // This is consumed by the lists. It can be set by any query or local operations.
  private jobsSubject = new BehaviorSubject<JobListElement[]>(undefined)
  $jobs = this.jobsSubject.asObservable()



  // Location Query
  ///////////////
  private locationQuerySubject = new BehaviorSubject<LocationQuery>({
    searchString: '',
    location: '',
    perimeter: 25,
  })
  $locationQuery = this.locationQuerySubject.asObservable();
  perimeterSearchResults$: Observable<JobListElement[] | null> = merge(
    // Immediately clear results on any query change.
    this.$locationQuery.pipe(map(() => null)),

    // Wait 300ms and then perform the search.
    this.$locationQuery.pipe(
      debounceTime(300),
      switchMap((state: LocationQuery) =>
        this.wordpressService.locationQuery(
          state.searchString,
          state.location,
          state.perimeter
        ).pipe(
          catchError(error => {
            console.error('Search endpoint error:', error);
            return of(null);
          }),
        )
      )
    )
  ).pipe(
    // Use a custom comparator so that two consecutive nulls are considered different.
    distinctUntilChanged((prev, curr) => {
      if (prev === null && curr === null) {
        return false;
      }
      return JSON.stringify(prev) === JSON.stringify(curr);
    }),
    shareReplay(1)
  );

  // Region Query
  ///////////////

  private regionQuerySubject = new BehaviorSubject<RegionQuery>({
    startCursor: 0,
    pageSize: 15,
    searchString: '',
    region: undefined
  })
  $regionQuery = this.regionQuerySubject.asObservable()

  private defautRegionUpdateSubject: Subject<boolean> = new ReplaySubject(1)

  private regionQueryResultsSubject: Subject<JobListElement[]> = new Subject<JobListElement[]>()
  $regionQueryResults = this.regionQueryResultsSubject.asObservable()

  constructor(
    private wordpressService: WordpressService,
    private readonly tracker: MatomoTracker
  ) {

  }

  public resetActiveRegion(): void {
    // TODO: reset search
    this.setQueryRegionByLabel(DEFAULT_REGION)
  }

  ngOnDestroy(): void {
    if (this.stateSub) {
      this.stateSub.unsubscribe()
    }
  }

  // Active List Job
  //////////////////

  setActiveJob(id: string): void {
    if (this.lastActiveJobId === id) {
      this.activeJobSubject.next(this.lastActiveJob)
      return
    }
    this.lastActiveJobId = id
    this.wordpressService
      .getJobById(id)
      .pipe(take(1))
      .subscribe((job) => {
        this.lastActiveJob = job
        this.activeJobSubject.next(job)
      })
  }

  newLocationQuery(searchString: string, location: string, perimeter: number): void {
    this.tracker.trackSiteSearch(searchString, location, perimeter);
    this.locationQuerySubject.next({
      searchString,
      location,
      perimeter
    });
  }

  setQueryLocationByLabel(regionLabel: string): void {
    const defaultPerimeter = 100;
    console.log(regionLabel)
    switch(regionLabel) {
      case 'Neuruppin':
        this.newLocationQuery('', 'Neuruppin', defaultPerimeter);
        break;
      case 'Uckermark / Barnim / Märkisch Oderland':
        this.newLocationQuery('', 'Eberswalde', defaultPerimeter);
        break;
      case 'Brandenburg / Havelland':
        this.newLocationQuery('', 'brandenburg an der havel', defaultPerimeter);
        break;
      case 'Elbe-Elster / Oberspreewald-Lausitz / Spree-Neiße':
        this.newLocationQuery('', 'Cottbus', defaultPerimeter);
        break;
      case 'Potsdam / Potsdam-Mittelmark':
        this.newLocationQuery('', 'Potsdam', defaultPerimeter);
        break;
      case 'Teltow-Fläming / Dahme-Spreewald':
        this.newLocationQuery('', 'Lübben', defaultPerimeter);
        break;
      case 'Frankfurt / Oder-Spree':
        this.newLocationQuery('', 'Frankfurt (Oder)', defaultPerimeter);
        break;
      case 'Berlin':
        this.newLocationQuery('', 'Berlin', defaultPerimeter);
        break;
      case 'Vorpommern-Rügen':
        this.newLocationQuery('', 'Stralsund', defaultPerimeter);
        break;
      case 'Vorpommern-Greifswald':
        this.newLocationQuery('', 'Greifswald', defaultPerimeter);
        break;
      case 'Mecklenburgische Seenplatte':
        this.newLocationQuery('', 'Neubrandenburg', defaultPerimeter);
        break;
      case 'Ludwigslust-Parchim':
        this.newLocationQuery('', 'Ludwigslust', defaultPerimeter);
        break;
      case 'Schwerin':
        this.newLocationQuery('', 'Schwerin', defaultPerimeter);
        break;
      case 'Nordwestmecklenburg':
        this.newLocationQuery('', 'Wismar', defaultPerimeter);
        break;
      case 'Landkreis Rostock':
        this.newLocationQuery('', 'Güstrow', defaultPerimeter);
        break;
      case 'Stadt Rostock':
        this.newLocationQuery('', 'Rostock', defaultPerimeter);
        break;
      default:
        throw new Error(`Unrecognized region: ${regionLabel}`);
    }
  }

  setQueryRegionByLabel(regionLabel: string): void {
    this.$regions
      .pipe(
        take(1),
        switchMap((regions) => {
          if (regions && regions.length > 0) {
            if (regionLabel !== DEFAULT_REGION) {
              return from(regions).pipe(
                filter((region) => region.label === regionLabel),
                take(1)
              )
            } else {
              return of(regions[0])
            }
          } else {
            return EMPTY
          }
        })
      )
      .subscribe((region) => {
        this.$regionQuery.pipe(take(1)).subscribe((regionQuery) => {
          this.regionQuerySubject.next({
            ...regionQuery,
            region
          })
          this.defautRegionUpdateSubject.next(true)
        })
      })
  }
}
