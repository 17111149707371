import {
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { JobService } from "../../../shared/services/job.service";
import { Router } from "@angular/router";
import { _jobs } from 'src/app/router/route-data'

interface SearchForm {
  searchString: FormControl<string>;
  location: FormControl<string>;
  perimeter: FormControl<number>;
}

@Component({
  selector: 'app-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss']
})
export class JobSearchComponent implements OnInit {
  @ViewChild('headContainer', { static: true }) headContainer

  _jobs = `${_jobs}`

  searchForm: FormGroup<SearchForm> = this.formBuilder.group({ searchString: [''], location: [''], perimeter: new FormControl(25) })

  get location(): AbstractControl<string, string> {
    return this.searchForm.get('location')
  }
  get searchString(): AbstractControl<string, string> {
    return this.searchForm.get('searchString')
  }
  get perimeter(): AbstractControl<number, number> {
    return this.searchForm.get('perimeter')
  }

  constructor(private formBuilder: FormBuilder, private jobService: JobService, private router: Router) {}

  ngOnInit() {
    this.searchString.valueChanges.subscribe((_) => {})
  }

  search(): void {
    this.jobService.newLocationQuery(this.searchString.value, this.location.value, this.perimeter.value)
    this.router.navigate([this._jobs]);
  }
}
