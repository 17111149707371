import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { debounceTime, distinctUntilChanged, Observable, of, tap, throwError } from "rxjs";
import {
  Job,
  Taxonomy,
  PostJob,
  JWTUserResponse,
  User,
  CustomPage,
  UpdateJob,
  CompanyCompany,
  CompanyJob,
  Bundle,
  SpecialOffer,
  JobListElement,
  Flatrate,
  CompanyForm,
  PostContact,
  ChatbotData,
  PostCompanyFlatrate,
  CompanyHighlight,
  UpdateCompany,
  UpdateContact,
  Company,
  NewsletterData,
  RadioBundle, Feedback, Radiostation, CompanyMedia, UpdateCompanyMedia, UserCompany, States
} from "../data/types";
import { HttpClient, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { map, switchMap, catchError, take } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { makeStateKey, TransferState } from "@angular/platform-browser";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

const HTTP_STATUS_CODES = {
  NOT_FOUND: 404,
  FORBIDDEN: 403
}

@Injectable({ providedIn: 'root' })
export class WordpressService {
  // Base routes
  base = environment.API_URL

  wpjson = `${this.base}wp-json`
  wpv2 = `${this.base}wp-json/wp/v2`

  // CRUD segments
  post = 'post'
  update = 'update'
  delete = 'delete'
  deactivate = 'deactivate'
  getById = 'getById'
  getByName = 'getByName'

  // Namespaces
  job = 'job'
  company = 'company'
  contact = 'contact'
  jobagent = 'jobagent'
  user = 'user'
  bundle = 'bundle'
  radiobundle = 'radiobundle'
  feedback = 'feedback'
  radioStation = 'radioStation'
  specialoffer = 'specialoffer'
  taxonomy = 'taxonomy'
  custompage = 'custompage'
  flatrate = 'flatrate'
  companyflatrate = 'companyflatrate'

  // Custom post type routes
  _job = `${this.wpjson}/${this.job}`
  _company = `${this.wpjson}/${this.company}`
  _contact = `${this.wpjson}/${this.contact}`
  _user = `${this.wpjson}/${this.user}`
  _bundle = `${this.wpjson}/${this.bundle}`
  _radiobundle = `${this.wpjson}/${this.radiobundle}`
  _feedback = `${this.wpjson}/${this.feedback}`
  _radioStation = `${this.wpjson}/${this.radioStation}`
  _specialoffer = `${this.wpjson}/${this.specialoffer}`
  _custompage = `${this.wpjson}/${this.custompage}`
  _taxonomy = `${this.wpjson}/${this.taxonomy}`
  _flatrate = `${this.wpjson}/${this.flatrate}`
  _companyflatrate = `${this.wpjson}/${this.companyflatrate}`
  _jobagent = `${this.wpjson}/${this.jobagent}`

  // Custom post type endpoints
  /////////////////////////////

  _system_incrementNumberOfPageViews = `${this.wpjson}/system/incrementNumberOfPageViews`

  // job
  _job_post = `${this._job}/${this.post}`
  _job_incrementNumberOfClicks = `${this._job}/incrementNumberOfClicks`
  _job_update = `${this._job}/${this.update}`
  _job_deactivate = `${this._job}/${this.deactivate}`
  _job_getById = `${this._job}/getById`
  _job_getCompanyJobById = `${this._job}/getCompanyJobById`
  _job_getJobByUrlSegment = `${this._job}/getJobByUrlSegment`

  _job_searchQuery = `${this._job}/searchQuery`
  _job_locationQuery = `${this._job}/testSearch`
  _job_regionQuery = `${this._job}/regionQueryNew`
  _job_getHighlightJobs = `${this._job}/getHighlightJobs`

  // company
  _company_post = `${this._company}/${this.post}`
  _company_update = `${this._company}/${this.update}`
  _company_addMedia = `${this._company}/addMedia`
  _company_removeMedia = `${this._company}/removeMedia`
  _company_delete = `${this._company}/${this.delete}`
  _company_getCompanyCompanyById = `${this._company}/getCompanyCompanyById`
  _company_getCompanyMediaById = `${this._company}/getCompanyMediaById`
  _company_getCompaniesByEmail = `${this._company}/getCompaniesByEmail`
  _company_getCompanyByUrlSegment = `${this._company}/getCompanyByUrlSegment`
  _company_getJobsByCompanyId = `${this._company}/getJobsByCompanyId`
  _company_getCompanyJobsByCompanyId = `${this._company}/getCompanyJobsByCompanyId`
  _company_companyJobsQuery = `${this._company}/companyJobsQuery`

  // contact
  _contact_post = `${this._contact}/${this.post}`
  _contact_update = `${this._contact}/${this.update}`
  _contact_delete = `${this._contact}/${this.delete}`
  _contact_getById = `${this._contact}/${this.getById}`

  // user
  _user_post = `${this._user}/${this.post}`
  _user_changePassword = `${this._user}/changePassword`
  _user_setChangedPassword = `${this._user}/setChangedPassword`
  _user_confirmUser = `${this._user}/confirmRegistration`
  _user_exists = `${this._user}/exists`

  // bundle
  _bundle_getAll = `${this._bundle}/getAll`

  // radio bundle
  _radiobundle_getAll = `${this._radiobundle}/getAll`

  // feedback
  _feedback_getAll = `${this._feedback}/getAll`

  // radioStation
  _radioStation_getAll = `${this._radioStation}/getAll`

  // bundle
  _specialoffer_getAll = `${this._specialoffer}/getAll`

  // flatrate
  _flatrate_getAll = `${this._flatrate}/getAll`
  _flatrate_byBundleId = `${this._flatrate}/byBundleId`

  // companyflatrate
  _companyflatrate_post = `${this._companyflatrate}/${this.post}`
  _companyflatrate_decreaseCompanyFlatrateQuota = `${this._companyflatrate}/decreaseCompanyFlatrateQuota`

  // taxonomy
  _taxonomy_getEmploymentForms = `${this._taxonomy}/getEmploymentForms`
  _taxonomy_getRegions = `${this._taxonomy}/getRegions`
  _taxonomy_getContactCategories = `${this._taxonomy}/getContactCategories`

  // custompage
  _custompage_getAll = `${this._custompage}/getAll`
  _custompage_getByUrlSegment = `${this._custompage}/getByUrlSegment`

  // contactForm
  _contactForm_contact = `${this.wpjson}/contactForm/contact`

  // debug
  _debug_failedUpload = `${this.wpjson}/debug/failedUpload`

  // chatbot
  _chatbot_post = `${this.wpjson}/chatbot/${this.post}`

  // newsletter
  _newsletter_post = `${this.wpjson}/newsletter/${this.post}`

  // jobagent
  _jobagent_subscribe = `${this._jobagent}/subscribe`
  _jobagent_confirm = `${this._jobagent}/confirm`
  _jobagent_unsubscribe = `${this._jobagent}/unsubscribe`

  // company highlights
  _getCompanyHighlights = `${this.wpjson}/company/getHighlights`

  // Wordpress
  _media = `${this.wpv2}/media`

  // JSON web token
  _token = `${this.wpjson}/jwt-auth/v1/token`
  _token_validate = `${this.wpjson}/jwt-auth/v1/token/validate`

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {
  }

  isTokenValid(token: string) {
    return this.http.post<boolean>(this._token_validate, { token: token }).pipe(catchError((error) => this.error(error)))
  }

  // chatbot
  //////////

  postChatbot(chatbotData: ChatbotData) {
    return this.http.post<string>(this._chatbot_post, chatbotData).pipe(catchError((error) => this.error(error)))
  }

  // newsletter
  //////////

  postNewsletter(newsletterData: NewsletterData) {
    return this.http.post<string>(this._newsletter_post, newsletterData).pipe(catchError((error) => this.error(error)))
  }

  // company highlights
  /////////////////////

  getCompanyHighlights(_stateId: States): Observable<CompanyHighlight[]> {
    const KEY_NAME = makeStateKey<CompanyHighlight[]>(`highlights${_stateId}`)
    if (isPlatformBrowser(this.platformId) && this.transferState.hasKey(KEY_NAME)) {
      const value = this.transferState.get(KEY_NAME, [])
      this.transferState.remove(KEY_NAME)
      return of(value)
    }
    return this.http.get<CompanyHighlight[]>(this._getCompanyHighlights, { params: { stateId: _stateId } }).pipe(
      tap((data) => {
        if (!isPlatformServer(this.platformId)) {
          return
        }
        this.transferState.set(KEY_NAME, data)
      }),
      catchError((error) => this.error(error))
    )
  }

  // jobagent
  ///////////

  jobagentSubscribe(searchString: string, regionId: string, email: string) {
    return this.http
      .post<string>(this._jobagent_subscribe, { searchString: searchString, regionId: regionId, email: email })
      .pipe(catchError((error) => this.error(error)))
  }

  jobagentConfirm(jobagentId: number) {
    return this.http.post<string>(this._jobagent_confirm, { jobagentId: jobagentId }).pipe(catchError((error) => this.error(error)))
  }

  jobagentUnsubscribe(jobagentId: number) {
    return this.http.post<string>(this._jobagent_unsubscribe, { jobagentId: jobagentId }).pipe(catchError((error) => this.error(error)))
  }

  // job
  //////

  incrementNumberOfPageViews() {
    return this.http
      .get(this._system_incrementNumberOfPageViews, {
        responseType: 'text'
      })
      .pipe(catchError((error) => this.error(error, 'incrementNumberOfPageViews')))
  }

  incrementNumberOfClicks(id: string, target: string) {
    return this.http
      .post<string>(this._job_incrementNumberOfClicks, { id: id, target: target })
      .pipe(catchError((error) => this.error(error, 'incrementNumberOfClicks')))
  }

  // Posts a job and returns the id observable.
  postJob(postJob: PostJob) {
    return this.http.post<string>(this._job_post, postJob).pipe(catchError((error) => this.error(error)))
  }

  updateJob(updateJob: UpdateJob) {
    return this.http.post<string>(this._job_update, updateJob).pipe(catchError((error) => this.error(error)))
  }

  deactivateJob(id: string, companyId: string) {
    return this.http
      .post<string>(this._job_deactivate, {
        id: id,
        companyId: companyId
      })
      .pipe(catchError((error) => this.error(error)))
  }

  getJobById(id: string) {
    return this.http.get<Job>(this._job_getById, { params: { id: id } }).pipe(catchError((error) => this.error(error)))
  }

  getJobByUrlSegment(urlSegment: string) {
    return this.http
      .get<Job>(this._job_getJobByUrlSegment, { params: { urlSegment: urlSegment } })
      .pipe(catchError((error) => this.error(error, 'getJobByUrlSegment')))
  }

  getCompanyJobById(id: string, companyId: string): Observable<CompanyJob> {
    return this.http
      .get<CompanyJob>(this._job_getCompanyJobById, {
        params: {
          id: id,
          companyId: companyId
        }
      })
      .pipe(catchError((error) => this.error(error)))
  }

  // job queries
  //////////////

  getHighlightJobs(_stateId: States): Observable<JobListElement[]> {
    const KEY_NAME = makeStateKey<JobListElement[]>(`highlightJobs${_stateId}`)
    if (isPlatformBrowser(this.platformId) && this.transferState.hasKey(KEY_NAME)) {
      const value = this.transferState.get(KEY_NAME, [])
      this.transferState.remove(KEY_NAME)
      return of(value);
    }
    return this.http
      .get<JobListElement[]>(this._job_getHighlightJobs, { params: { stateId: _stateId } })
      .pipe(catchError((error) => this.error(error))).pipe(
        tap((data) => {
          if (!isPlatformServer(this.platformId)) {
            return;
          }
          this.transferState.set(KEY_NAME, data);
        })
      );
  }

  locationQuery(searchString: string, location: string, perimeter: number): Observable<JobListElement[]> {
    return this.http
      .get<JobListElement[]>(this._job_locationQuery, {
        params: {
          searchString,
          location,
          perimeter
        }
      })
      .pipe(catchError((error) => this.error(error)))
  }
  getAutocompleteResults(query: string): Observable<string[]> {
    if (!query || query.length < 3) {
      return of([]); // Return empty array if query is too short
    }

    return this.http.get<string[]>(`${this.wpjson}/job/getPlaces?searchString=${query}`).pipe(
      debounceTime(300), // Prevent excessive API calls
      distinctUntilChanged(),
      map(results => results.map(city => city.replace(', Germany', ''))),
      switchMap(response => of(response)),
      catchError(() => of([])) // Handle errors gracefully
    );
  }

  regionQuery(searchString: string, regionId: string, startCursor: number, pageSize: number): Observable<JobListElement[]> {
    return this.http
      .get<JobListElement[]>(this._job_regionQuery, {
        params: {
          startCursor: startCursor.toString(),
          pageSize: pageSize.toString(),
          searchString: searchString,
          regionId: regionId
        }
      })
      .pipe(catchError((error) => this.error(error)))
  }

  // company
  //////////

  postCompany(postCompany: CompanyForm) {
    return this.http.post<string>(this._company_post, postCompany).pipe(catchError((error) => this.error(error)))
  }

  updateCompany(updateCompany: UpdateCompany) {
    if (updateCompany.logoFile) {
      return this.uploadImage(updateCompany.logoFile).pipe(
        map((wpImage) => (updateCompany.logo = wpImage.id)),
        switchMap(() => this.http.post<string>(this._company_update, updateCompany).pipe(catchError((error) => this.error(error))))
      )
    } else {
      updateCompany.logo = ''
      return this.http.post<string>(this._company_update, updateCompany).pipe(catchError((error) => this.error(error)))
    }
  }

  addCompanyMedia(updateCompanyMedia: UpdateCompanyMedia) {
    return this.http.post<string>(this._company_addMedia, updateCompanyMedia).pipe(catchError((error) => this.error(error)))
  }

  removeCompanyMedia(updateCompanyMedia: UpdateCompanyMedia): Observable<boolean> {
    return this.http.post<boolean>(this._company_removeMedia, updateCompanyMedia)
  }

  getCompanyMediaById(id: string): Observable<CompanyMedia> {
    return this.http
      .get<CompanyMedia>(this._company_getCompanyMediaById, { params: { id: id } })
      .pipe(catchError((error) => this.error(error, 'getCompanyMediaById')))
  }

  getCompanyCompanyById(id: string) {
    return this.http
      .get<CompanyCompany>(this._company_getCompanyCompanyById, { params: { id: id } })
      .pipe(catchError((error) => this.error(error, 'getCompanyCompanyById')))
  }

  getCompanyJobsByCompanyId(id: string): Observable<CompanyJob[]> {
    return this.http
      .get<CompanyJob[]>(this._company_getCompanyJobsByCompanyId, { params: { id: id } })
      .pipe(catchError((error) => this.error(error, 'getCompanyJobsByCompanyId')))
  }

  companyJobsQuery(id: string, startCursor: number, pageSize: number, searchString = ''): Observable<CompanyJob[]> {
    return this.http
      .get<CompanyJob[]>(this._company_companyJobsQuery, {
        params: { id: id, searchString: searchString, startCursor: startCursor, pageSize: pageSize }
      })
      .pipe(catchError((error) => this.error(error, 'companyJobsQuery')))
  }

  getCompanyByUrlSegment(urlSegment: string) {
    return this.http
      .get<Company>(this._company_getCompanyByUrlSegment, { params: { urlSegment: urlSegment } })
      .pipe(catchError((error) => this.error(error, 'getCompanyByUrlSegment')))
  }

  getJobsByCompanyId(companyId: string) {
    return this.http
      .get<Job[]>(this._company_getJobsByCompanyId, { params: { companyId: companyId } })
      .pipe(catchError((error) => this.error(error, 'getJobsByCompanyId')))
  }

  // contact
  //////////

  deleteContact(id: string, companyId: string): Observable<string> {
    return this.http
      .post<string>(this._contact_delete, {
        id,
        companyId
      })
      .pipe(catchError((error) => this.error(error)))
  }

  updateContact(updateContact: UpdateContact) {
    return this.http.post<string>(this._contact_update, updateContact).pipe(catchError((error) => this.error(error)))
  }

  postContact(postContact: PostContact) {
    return this.http.post<string>(this._contact_post, postContact).pipe(catchError((error) => this.error(error)))
  }

  // bundles
  /////////

  getAllOnlineBundles(_stateId: string): Observable<Bundle[]> {
    return this.http
      .get<Bundle[]>(this._bundle_getAll, { params: { stateId: _stateId } })
      .pipe(catchError((error) => this.error(error, 'getAllOnlineBundles')))
  }

  getAllRadioBundles(_stateId: string): Observable<RadioBundle[]> {
    return this.http
      .get<RadioBundle[]>(this._radiobundle_getAll, { params: { stateId: _stateId } })
      .pipe(catchError((error) => this.error(error, 'getAllRadioBundles')))
  }

  // feedback
  /////////
  getCustomerFeedback(_stateId: string): Observable<Feedback[]> {
    return this.http
      .get<Feedback[]>(this._feedback_getAll, { params: { stateId: _stateId } })
      .pipe(catchError((error) => this.error(error, 'getCustomerFeedback')))
  }

  // radiostations
  /////////
  getRadiostations(_stateId: string): Observable<Radiostation[]> {
    return this.http
      .get<Radiostation[]>(this._radioStation_getAll, { params: { stateId: _stateId } })
      .pipe(catchError((error) => this.error(error, 'getRadiostations')))
  }

  // custompage
  /////////////
  getAllCustomPages() {
    return this.http.get<CustomPage[]>(this._custompage_getAll).pipe(catchError((error) => this.error(error, 'getAllCustomPages')))
  }

  getCustomPageByUrlSegment(urlSegment: string) {
    return this.http
      .get<CustomPage>(this._custompage_getByUrlSegment, { params: { urlSegment: urlSegment } })
      .pipe(catchError((error) => this.error(error)))
  }

  // specialoffer
  ///////////////

  getAllSpecialOffers() {
    return this.http.get<SpecialOffer[]>(this._specialoffer_getAll).pipe(catchError((error) => this.error(error)))
  }

  // taxonomy
  ///////////

  getEmploymentForms() {
    return this.http.get<Taxonomy[]>(this._taxonomy_getEmploymentForms).pipe(catchError((error) => this.error(error)))
  }

  /**
   * @return Taxonomy[]
   */
  getRegions() {
    return this.http.get<Taxonomy[]>(this._taxonomy_getRegions).pipe(catchError((error) => this.error(error, 'getRegions')))
  }

  // TODO: unused
  getContactCategories() {
    return this.http.get<Taxonomy[]>(this._taxonomy_getContactCategories).pipe(catchError((error) => this.error(error)))
  }

  getAllFlatrates(): Observable<Flatrate[]> {
    const KEY_NAME = makeStateKey<Flatrate[]>(`flatrates`)
    if (isPlatformBrowser(this.platformId) && this.transferState.hasKey(KEY_NAME)) {
      // flatrates don't change often so we don't remove them from transferstate
      const value = this.transferState.get(KEY_NAME, [])
      return of(value)
    }
    return this.http.get<Flatrate[]>(this._flatrate_getAll).pipe(
      tap((data) => {
        if (!isPlatformServer(this.platformId)) {
          return
        }
        this.transferState.set(KEY_NAME, data)
      }),
      catchError((error) => this.error(error))
    )
  }

  getFlatratesByBundleId(bundleId: string) {
    return this.http
      .get<Flatrate[]>(this._flatrate_byBundleId, { params: { bundleId: bundleId } })
      .pipe(catchError((error) => this.error(error)))
  }

  // companyflatrate
  //////////////////

  postCompanyFlatrate(postCompanyFlatrate: PostCompanyFlatrate) {
    return this.http.post(this._companyflatrate_post, postCompanyFlatrate).pipe(catchError((error) => this.error(error)))
  }

  decreaseCompanyFlatrateQuota(companyFlatrateId: string, companyId: string) {
    return this.http
      .post(this._companyflatrate_decreaseCompanyFlatrateQuota, {
        companyFlatrateId,
        companyId
      })
      .pipe(catchError((error) => this.error(error)))
  }

  // user
  ///////

  confirmUser(key: string, requestId: string, companyId: string) {
    return this.http
      .post(this._user_confirmUser, { key: key, requestId: requestId, companyId: companyId })
      .pipe(catchError((error) => this.error(error)))
  }

  userExists(email: string) {
    return this.http.post(this._user_exists, { email: email }).pipe(catchError((error) => this.error(error)))
  }

  registerUser(email: string, password: string, companyId: string, salutation: string, firstName: string, name: string) {
    return this.http
      .post(this._user_post, {
        email: email,
        password: password,
        companyId: companyId,
        salutation: salutation,
        firstName: firstName,
        name: name
      })
      .pipe(catchError((error) => this.error(error)))
  }

  changePassword(email: string) {
    return this.http
      .post(
        this._user_changePassword,
        { email: email },
        {
          observe: 'response'
        }
      )
      .pipe(catchError((error) => this.error(error)))
  }

  setChangedPassword(key: string, login: string, password: string) {
    return this.http
      .post(this._user_setChangedPassword, { key: key, login: login, password: password })
      .pipe(catchError((error) => this.error(error)))
  }

  // contact form
  ///////////////

  contactForm(formData: any) {
    return this.http.post(this._contactForm_contact, { formData: formData }).pipe(catchError((error) => this.error(error)))
  }

  // debug

  failedUpload(serialized: string) {
    return this.http.post(this._debug_failedUpload, { serialized: serialized }).pipe(catchError((error) => this.error(error)))
  }

  uploadImage(file: File, token?: string): Observable<any> {
    const p = this._media
    const fd = new FormData()
    fd.append('file', file)
    const headers = { 'Content-Disposition': `attachment; filename=\"${file.name}\"` };
    return token
      ? this.http.post(p, fd, { headers: headers, params: { superuserpost: token } })
      : this.http.post(p, fd, { headers: headers })
  }

  uploadImageWithProgress(file: File, token?: string): Observable<HttpEvent<any>> {
    const p = this._media
    const fd = new FormData()
    fd.append('file', file)
    const headers = {} // { 'Content-Disposition': `attachment; filename=\"${file.name}\"` };
    return token
      ? this.http.post(p, fd, { reportProgress: true, observe: 'events', headers: headers, params: { superuserpost: token } })
      : this.http.post(p, fd, { reportProgress: true, observe: 'events', headers: headers })
  }

  error(error: HttpErrorResponse, caller = '') {
    let errorMessage = `Beim Laden der Daten ist ein Fehler aufgetreten (${caller})`

    if (error.status === HTTP_STATUS_CODES.FORBIDDEN) {
      errorMessage = 'Zugriff verweigert'
    } else if (error.message) {
      errorMessage = error.message
    } else if (error.error.message) {
      errorMessage = error.error.message
    }

    return throwError(() => errorMessage)
  }

  // Helper to generate disabled routes from a whitelist (result used in functions.php)
  generateDisabledRoutes() {
    const whiteList = ['/', '/jwt-auth/v1/token', '/user/registration', '/job/query', '/companyAddresses/byId', '/companyContacts/byId']
    this.http.get<any>(this.wpjson + '/').subscribe((api) => {
      let isWhite: boolean
      Object.keys(api.routes).forEach((route) => {
        isWhite = false
        whiteList.forEach((whiteRoute) => {
          if (whiteRoute === route) {
            isWhite = true
            return
          }
        })
        isWhite ? console.log(`// '${route}',`) : console.log(`'${route}',`)
      })
    })
  }

  getSuperUserToken(username: string, password: string): Observable<string> {
    return this.http
      .post<JWTUserResponse>(this._token, { username: username, password: password }, { params: { superuser: 'superuser' } })
      .pipe(map((user) => user.token))
  }

  loginUser(username: string, password: string): Observable<User> {
    return this.http.post<JWTUserResponse>(this._token, { username, password }).pipe(
      map((user) => {
        return {
          name: user.user_display_name,
          email: user.user_email,
          token: user.token,
          companyId: user.companyId ? user.companyId : undefined,
          companies: user.companies,
          companyCustomerId: user.companyCustomerId
        }
      })
    )
  }

  getCompaniesByEmail(email: string, companyId: string): Observable<UserCompany[]> {
    return this.http.get<UserCompany[]>(this._company_getCompaniesByEmail, { params: { id: companyId, email: email } }).pipe(take(1))
  }
}
