import { Component, OnInit, OnDestroy } from '@angular/core'
import { RouterService } from 'src/app/router/router.service'
import { Subscription } from 'rxjs'
import { HeroState } from 'src/app/router/route-data'

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit, OnDestroy {
  heroStateSub: Subscription
  showRegionSelect = false
  showRegionBbSelect = false
  showCompanyBanner = false
  showJobSearch = false
  showJobSearchSmall = false

  constructor(private routerService: RouterService) {}

  ngOnInit(): void {
    this.heroStateSub = this.routerService.$heroState.subscribe((heroState) => {
      this.showCompanyBanner = false
      this.showRegionSelect = false
      this.showRegionBbSelect = false
      this.showJobSearchSmall = false
      this.showJobSearch = false
      switch (heroState) {
        case HeroState.companyBanner:
          this.showCompanyBanner = true
          break
        case HeroState.jobSearchSmall:
          this.showJobSearchSmall = true
          break
        case HeroState.jobSearch:
          this.showJobSearch = true
          break
        default:
          break
      }
    })
  }

  ngOnDestroy(): void {
    if (this.heroStateSub) {
      this.heroStateSub.unsubscribe()
    }
  }
}
