<div #headContainer class="headContainer fixed-top d-none d-sm-flex align-items-center justify-content-center">
  <form class="mt-2 row" [formGroup]="searchForm">
    <div class="mr-2">
      <input
        type="text"
        class="form-control border rounded"
        placeholder="Jobtitel oder Stichwort"
        formControlName="searchString"
        (keydown.enter)="search()"
      />
    </div>
    <div class="mr-2">
      <input
        type="text"
        class="form-control border rounded"
        placeholder="Ort oder Postleitzahl"
        formControlName="location"
        [typeahead]="autocompleteLocations"
        [isAnimated]="true"
        [typeaheadMinLength]="3"
        [typeaheadSelectFirstItem]="true"
        (typeaheadOnSelect)="searchForm.controls.location.setValue($event.item)"
        (keydown.enter)="search()"
      />
    </div>
    <div>
      <select class="form-control" aria-label="Umkreis" name="perimeter" formControlName="perimeter">
        <option *ngFor="let perimeter of [25, 50, 100]" [value]="perimeter">
          {{ perimeter }} km Umkreis
        </option>
      </select>
    </div>
    <a rel="nofollow" class="btn btn-secondary ml-2" (click)="search()" href="javascript:void(0)"><span class="fa fa-search mr-2"></span>Suchen</a>
  </form>
</div>
